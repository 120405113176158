// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
} */

.section-padding-large {
  padding-top: 80px;
  padding-bottom: 100px;
}

.section-title {
  margin-bottom: 35px;
  color: #f2f2f2;
}

.section-title {
  margin-bottom: 35px;
}

.section-title {
  margin-bottom: 0px;
}

.section-title-left h2:after {
  left: 0;
}

.section-title-m50 {
  margin-bottom: 50px;
}

.our-gallery-content {
  margin-top: -5px;
}
.our-gallery-content .row {
  margin-left: -5px;
  margin-right: -5px;
}
.our-gallery-content .item {
  width: 25%;
  padding: 5px;
}
.our-gallery-content .item figure {
  overflow: hidden;
}
.our-gallery-content .item figure img {
  transition: opacity 1s, transform 1s;
}
.our-gallery-content .item figure:hover img {
  opacity: 0.8;
  transform: scale3d(1.1, 1.1, 1);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Gallery.css"],"names":[],"mappings":"AAAA;;;;;;;;;GASG;;AAEH;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EAEE,oCAAoC;AACtC;AACA;EACE,YAAY;EAEZ,+BAA+B;AACjC","sourcesContent":["/* .container {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  -moz-transform: translate(-50%, -50%);\n  -webkit-transform: translate(-50%, -50%);\n  -o-transform: translate(-50%, -50%);\n  -ms-transform: translate(-50%, -50%);\n} */\n\n.section-padding-large {\n  padding-top: 80px;\n  padding-bottom: 100px;\n}\n\n.section-title {\n  margin-bottom: 35px;\n  color: #f2f2f2;\n}\n\n.section-title {\n  margin-bottom: 35px;\n}\n\n.section-title {\n  margin-bottom: 0px;\n}\n\n.section-title-left h2:after {\n  left: 0;\n}\n\n.section-title-m50 {\n  margin-bottom: 50px;\n}\n\n.our-gallery-content {\n  margin-top: -5px;\n}\n.our-gallery-content .row {\n  margin-left: -5px;\n  margin-right: -5px;\n}\n.our-gallery-content .item {\n  width: 25%;\n  padding: 5px;\n}\n.our-gallery-content .item figure {\n  overflow: hidden;\n}\n.our-gallery-content .item figure img {\n  -webkit-transition: opacity 1s, -webkit-transform 1s;\n  transition: opacity 1s, transform 1s;\n}\n.our-gallery-content .item figure:hover img {\n  opacity: 0.8;\n  -webkit-transform: scale3d(1.1, 1.1, 1);\n  transform: scale3d(1.1, 1.1, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
