// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-events {
  margin-bottom: 60px;
}
.filter-events li span {
  font-family: "Roboto Slab";
  color: #333;
  font-weight: bold;
  margin: 8px 25px;
  padding: 0;
  padding-bottom: 10px;
  border: none;
  background: transparent;
  outline: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  -ms-border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
}
.filter-events li .is-checked {
  text-transform: uppercase;
  border-bottom: 2px solid #ffa037;
}

.events-page {
  padding-top: 80px;
  padding-bottom: 40px;
}
.events-page .events-grid .item {
  margin-bottom: 60px;
}

.events-page-two .events-content .item .info {
  padding: 35px 0px 0 0px;
}
.events-page-two .events-content .item .info .desc {
  padding: 0 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Calender.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,0BAA0B;EAC1B,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,UAAU;EACV,oBAAoB;EACpB,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,0BAA0B;EAC1B,qBAAqB;EACrB,sBAAsB;EACtB,eAAe;EACf,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,eAAe;AACjB","sourcesContent":[".filter-events {\n  margin-bottom: 60px;\n}\n.filter-events li span {\n  font-family: \"Roboto Slab\";\n  color: #333;\n  font-weight: bold;\n  margin: 8px 25px;\n  padding: 0;\n  padding-bottom: 10px;\n  border: none;\n  background: transparent;\n  outline: none;\n  border-radius: 0px;\n  -moz-border-radius: 0px;\n  -webkit-border-radius: 0px;\n  -o-border-radius: 0px;\n  -ms-border-radius: 0px;\n  font-size: 18px;\n  cursor: pointer;\n}\n.filter-events li .is-checked {\n  text-transform: uppercase;\n  border-bottom: 2px solid #ffa037;\n}\n\n.events-page {\n  padding-top: 80px;\n  padding-bottom: 40px;\n}\n.events-page .events-grid .item {\n  margin-bottom: 60px;\n}\n\n.events-page-two .events-content .item .info {\n  padding: 35px 0px 0 0px;\n}\n.events-page-two .events-content .item .info .desc {\n  padding: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
