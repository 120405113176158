// store.js
import { configureStore } from '@reduxjs/toolkit';
import admissionReducer from './slice/admissionSlice'; // Ensure the path is correct

const store = configureStore({
  reducer: {
    admissions: admissionReducer, // This should point to a valid reducer function
  },
});

export default store;