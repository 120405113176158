// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our-story-content .section-title {
  margin-bottom: 35px;
}
.our-story-content p {
  margin-bottom: 18px;
}
.our-story-content ul li {
  width: 50%;
  float: left;
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  color: #333;
  font-size: 16px;
  margin-bottom: 12px;
  cursor: pointer;
}
.our-story-content ul li i {
  color: #ffa037;
  font-size: 16px;
  padding-right: 10px;
}

.section-title-left h2:after {
  left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/About.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,WAAW;EACX,iCAAiC;EACjC,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,OAAO;AACT","sourcesContent":[".our-story-content .section-title {\n  margin-bottom: 35px;\n}\n.our-story-content p {\n  margin-bottom: 18px;\n}\n.our-story-content ul li {\n  width: 50%;\n  float: left;\n  font-family: \"Roboto Slab\", serif;\n  font-weight: bold;\n  color: #333;\n  font-size: 16px;\n  margin-bottom: 12px;\n  cursor: pointer;\n}\n.our-story-content ul li i {\n  color: #ffa037;\n  font-size: 16px;\n  padding-right: 10px;\n}\n\n.section-title-left h2:after {\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
