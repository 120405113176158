// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_container__\\+Ihqx {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.Login_buttonContainer__19hCV {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.Login_button__8MvAw {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  flex: 1 1;
  margin: 0 5px;
}

.Login_button__8MvAw:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.Login_form__HoQ6M {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Login_input__6Emyd {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.Login_errorMessage__C8kjY {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iDAAiD;EACjD,SAAO;EACP,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".container {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px;\n  text-align: center;\n}\n\n.buttonContainer {\n  display: flex;\n  justify-content: space-around;\n  margin: 20px 0;\n}\n\n.button {\n  padding: 10px 20px;\n  font-size: 16px;\n  color: #fff;\n  background-color: #007bff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s, transform 0.2s;\n  flex: 1;\n  margin: 0 5px;\n}\n\n.button:hover {\n  background-color: #0056b3;\n  transform: scale(1.05);\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.input {\n  width: 100%;\n  padding: 10px;\n  margin: 10px 0;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.errorMessage {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Login_container__+Ihqx`,
	"buttonContainer": `Login_buttonContainer__19hCV`,
	"button": `Login_button__8MvAw`,
	"form": `Login_form__HoQ6M`,
	"input": `Login_input__6Emyd`,
	"errorMessage": `Login_errorMessage__C8kjY`
};
export default ___CSS_LOADER_EXPORT___;
