// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/bg-video-tour.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-tour-content {
  background: transparent url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center
    center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-attachment: fixed;
  padding: 95px 0;
  cursor: pointer;
  position: relative;
}
.video-tour-content:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
}
.video-tour-content .video {
  text-align: center;
  position: relative;
  z-index: 99;
}
.video-tour-content .video .video-title {
  padding-bottom: 25px;
  font-size: 48px;
  color: #fff;
}
.video-tour-content .video .video-title span {
  color: #ffa037;
  display: block;
  text-align: center;
}
.video-tour-content .video span {
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/Components/VideoTour.css"],"names":[],"mappings":"AAAA;EACE;UACQ;EACR,sBAAsB;EACtB,2BAA2B;EAC3B,8BAA8B;EAC9B,yBAAyB;EACzB,0BAA0B;EAC1B,4BAA4B;EAC5B,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,SAAS;EACT,QAAQ;EACR,WAAW;EACX,oCAAoC;EACpC,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,oBAAoB;EACpB,eAAe;EACf,WAAW;AACb;AACA;EACE,cAAc;EACd,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB","sourcesContent":[".video-tour-content {\n  background: transparent url(\"../assets/bg-video-tour.jpg\") no-repeat center\n    center;\n  background-size: cover;\n  -moz-background-size: cover;\n  -webkit-background-size: cover;\n  -o-background-size: cover;\n  -ms-background-size: cover;\n  background-attachment: fixed;\n  padding: 95px 0;\n  cursor: pointer;\n  position: relative;\n}\n.video-tour-content:after {\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  content: \"\";\n  background-color: rgba(0, 0, 0, 0.8);\n  z-index: 9;\n}\n.video-tour-content .video {\n  text-align: center;\n  position: relative;\n  z-index: 99;\n}\n.video-tour-content .video .video-title {\n  padding-bottom: 25px;\n  font-size: 48px;\n  color: #fff;\n}\n.video-tour-content .video .video-title span {\n  color: #ffa037;\n  display: block;\n  text-align: center;\n}\n.video-tour-content .video span {\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
