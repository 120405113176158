// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our-team-content  {
    margin-bottom: 55px; }

    .title{
        text-align: center;
    }
    .title a{
        list-style: none;
        text-decoration: none;
    }`, "",{"version":3,"sources":["webpack://./src/Components/Ourteam.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB,EAAE;;IAErB;QACI,kBAAkB;IACtB;IACA;QACI,gBAAgB;QAChB,qBAAqB;IACzB","sourcesContent":[".our-team-content  {\n    margin-bottom: 55px; }\n\n    .title{\n        text-align: center;\n    }\n    .title a{\n        list-style: none;\n        text-decoration: none;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
